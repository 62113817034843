.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background-color: var(--color-white-smoke);
  padding-right: 20px;
  padding-left: 20px;
  position: sticky;
  top: 0;
  z-index: 10;
}

.Header_m{
  display: flex;
  justify-content: space-between;
}

.Header_right {
  display: flex;
  align-items: center;
}

.Header_right > .ant-badge {
  margin-right: 20px;
  font-size: 20px;
  color: var(--color-gray);
  cursor: pointer;
}

.Header_right > .ant-avatar {
  cursor: pointer;
  /* border: 1px solid var(--color-gray); */
}

.Header_left {
  display: flex;
  align-items: center;
}

.Header_left > .Header_main {
  margin-left: 10px;
}

.toggle-menu-btn {
  font-size: 18px;
  cursor: pointer;
  color: var(--color-text);
}

.Header_main_top {
  display: flex;
  align-items: center;
}

.Header_main_top > img {
  width: 45px;
  margin-right: 10px;
  border-radius: 50%;
  object-fit: cover;
}

.Header_main_top_right > p {
  font-size: 16px;
  color: var(--color-text);
  font-weight: 600;
  margin-bottom: -5px;
}

.user_pro_name{
  margin-right: 10px;
  text-align: right;
}

.user_pro_name > p{
  font-size: 12px;
  color: var(--color-text);
  font-weight: 500;
  margin-bottom: -9px;
}

.user_pro_name > span{
  font-size: 10px;
  color: var(--color-text);
}

.system_update {
  display: flex;
  justify-content: center;
  background-color: red;
  color: white;
  font-size: 14px;
  font-weight: 600;
}

.ex_webLink {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin-right: 15px;
  color: #646464;
}