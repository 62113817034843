.dashboard {
  padding: 15px 20px;
}

.bg_layer {
  background-color: var(--color-white-smoke);
  min-height: 100vh;
}

.box_section {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-bottom: 20px;
}

.dashboard_sidebar {
  width: 20%;
}

.dashboard_panel {
  width: 80%;
}

.section_row_hello {
  background-color: #096dd94d;
  border-radius: 10px;
  /* box-shadow: var(--box-shadow-base); */
  padding: 15px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin-top: 20px;
}

.section_row_hello .hello {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  text-align: center;
}

.section_row_hello .hello > h1 {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 10px;
  color: #2f2f2f;
}

.section_row_hello .hello > p {
  font-size: 14px;
  font-weight: 400;
  color: #2f2f2f;
}

.section_row_hello > img {
  display: flex;
  align-items: center;
  width: 30%;
  width: 32%;
  margin-top: -60px;
}

.section_row {
  background-color: var(--color-white);
  border-radius: 10px;
  box-shadow: var(--box-shadow-base);
  padding: 15px;
  margin-bottom: 20px;
}

.section_row_chart {
  background-color: var(--color-white);
  border-radius: 10px;
  box-shadow: var(--box-shadow-base);
  padding: 10px;
  height: 300px;
  margin-bottom: 20px;
}

.section_title {
  font-size: var(--font-size-lg);
  font-weight: 600;
  color: var(--color-text);
}

.top_row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  gap: 5px;
}

.bottom_row {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.full_row {
  display: flex;
  flex: 1;
}

.edit_section {
  flex: 0.62;
}

.status_section {
  flex: 0.38;
  padding-left: 10px;
}

.status_container {
  background-color: whitesmoke;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 0 2px 0px #0000006e;
  border-radius: 10px;
  margin-bottom: 20px;
}

.status_bar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  color: white;
  font-weight: 600;
  font-size: var(--scolor-text);
  margin-bottom: 10px;
  cursor: pointer;
}

.status_bar_active {
  background-color: #27ae60;
}

.status_bar_pending {
  background-color: #ffc940;
  color: var(--color-text);
}

.status_bar_inactive {
  background-color: #bd0000;
}

.status_bar_default {
  background-color: #525252;
}

.edit_container {
  background-color: var(--color-white);
  border-radius: 10px;
  padding: 15px;
  /* box-shadow: 0 0 2px 0px #0000006e; */
  box-shadow: var(--box-shadow-base);
  border-radius: 10px;
  margin-bottom: 20px;
}

.request_row {
  display: flex;
  justify-content: flex-end;
  margin-top: -30px;
  margin-bottom: 15px;
}

.remaining_time {
  font-size: 30px;
  font-weight: 600;
  color: var(--color-text);
  margin-bottom: 10px;
}

.remaining_time > span {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 600;
}

.setting_box {
  margin: 0;
  padding: 0;
}

.setting_box > li {
  background-color: white;
  border-radius: 10px;
  padding: 10px 10px;
  margin-bottom: 2px;
}

.setting_box_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.setting_box > li:hover {
  background-color: whitesmoke;
}

.setting_box_title > h3 {
  font-size: var(--font-size-base);
  color: var(--color-text);
  margin: 0;
  padding: 0;
}

.setting_box_title > p {
  font-size: var(--font-size-xs);
  color: var(--color-text);
  margin: 0;
  padding: 0;
  margin-top: -3px;
}

.setting_box_content > .anticon {
  font-size: 16px;
  color: var(--color-text);
}

.switch_line {
  display: flex;
  align-items: center;
}

.switch_line > p {
  margin-left: 10px;
}

.common_header {
  margin: 0;
  padding: 0;
  font-size: var(--font-size-base);
  color: var(--color-text);
  font-weight: 500;
  line-height: 1.3;
  display: flex;
  align-items: center;
  /* margin-bottom: 20px; */
}

.common_header > span {
  margin: 0;
  padding: 0;
  font-size: var(--font-size-sm);
  color: var(--color-gray);
  font-weight: 400;
  margin-left: 5px;
}

.row_2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.input_field {
  width: 50%;
}

.left_btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}

.cus_data > p {
  margin-bottom: 5px;
  font-size: var(--font-size-sm);
  color: var(--color-text);
  border-bottom: 1px solid #e3e3e3;
}

.cus_data > p > span {
  font-weight: 600;
}

.cus_image > img {
  object-fit: cover;
  height: 150px;
  width: 150px;
}

.check_list {
  margin-bottom: 7px;
}

.step_board {
  margin-top: 5px;
}

.uni_data_dox {
  background-color: whitesmoke;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.doc_list {
  display: inline-block;
  list-style: none;
}

.doc_list > li {
  display: inline-block;
  width: 180px;
  margin-bottom: 20px;
  margin-right: 10px;
}

.doc_list > li > p {
  margin: 0;
  padding: 0;
  font-size: var(--font-size-sm);
  color: var(--color-text);
  font-weight: 500;
}

.edu_section {
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  gap: 20px;
}

.app_view_section {
  display: flex;
  justify-content: space-between;
}

.app_view_nav {
  width: 24%;
}
.app_view_body {
  width: 74%;
}
.edu_nav_btn {
  padding: 10px;
  background-color: var(--color-white-smoke);
  border-radius: 10px;
  margin-bottom: 3px;
  cursor: pointer;
  color: var(--color-text);
}

.edu_nav_btn:hover {
  background-color: var(--color-primary);
  color: var(--color-white);
  transition: var(--transition);
}

.edu_nav_btn_active {
  background-color: var(--color-primary);
  color: var(--color-white);
  transition: var(--transition);
}

.edu_pp {
  transition: var(--transition);
}

.ant-input::placeholder {
  color: rgb(165, 165, 165);
}

.ant-picker-input input::placeholder {
  color: rgb(165, 165, 165);
}

.ant-select-selection-placeholder {
  color: rgb(165, 165, 165);
}

.collapseList {
  margin-bottom: 20px;
}

.app_upload {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.empty_box_li {
  margin: 0 !important;
}

.empty_box {
  background-color: whitesmoke;
  border: 1px dashed gray;
  border-radius: 10px;
  text-align: center;
  width: 165px;
  display: table;
  padding: 60px 10px;
  cursor: pointer;
}

.is_ref_doc {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
}

.assign_box {
  text-align: center;
  background-color: #faad147a;
  border: 1px solid #faad14;
  margin-bottom: 3px;
  color: #644300;
  font-size: 10px;
  cursor: pointer;
}

.assign_box_not {
  text-align: center;
  background-color: #d9363e52;
  border: 1px solid #d9363e;
  margin-bottom: 3px;
  color: #d9363e;
  font-size: 10px;
  cursor: pointer;
}

.assign_box_deleted {
  text-align: center;
  background-color: #ff000d;
  border: 1px solid #ff000d;
  margin-bottom: 3px;
  color: #ffffff;
  font-size: 10px;
  cursor: pointer;
}

.assign_box > .app_name {
  font-weight: 500;
}

.assign_box_not > .app_name {
  font-weight: 500;
}

.assign_box_deleted > .app_name {
  font-weight: 500;
  text-decoration: line-through;
}

.view_box_body {
  margin-top: 15px;
}

.view_row {
  display: grid;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  grid-template-columns: 30% 70%;
  gap: 20px;
}

.view_row > .type {
  font-size: var(--font-size-base);
  color: var(--color-text);
  font-weight: 500;
}

.view_row > .data {
  font-size: var(--font-size-base);
  color: var(--color-text);
}

.view_doc_btn {
  background-color: #234caa4f;
  padding: 5px 10px;
  font-size: var(--font-size-base);
  border: 1px solid #234caa;
  border-radius: 5px;
  font-weight: 500;
}

.view_doc_btn > i {
  margin-left: 10px;
}

.view_doc_btn:hover {
  background-color: #234caa;
  color: #fff;
  transition: var(--transition);
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  gap: 20px;
}

.color_tab {
  width: 50px;
  height: 16px;
  border: 1px solid #adadad;
  border-radius: 5px;
}

.color_row {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
}

.color_row > p {
  margin: 0;
  font-size: var(--font-size-base);
  color: var(--color-text);
}

.refreshBtn {
  font-size: 30px;
  margin-right: 10px;
  cursor: pointer;
  color: #646464;
  /* background-color: #787878; */
  padding: 4px;
  border-radius: 5px;
}

.refreshBtn:hover {
  color: var(--color-text);
  transition: var(--transition);
}

.alertTxt > li {
  font-size: 12px;
  line-height: 1.5;
}

.alLink {
  color: #2f69f0;
  font-weight: 600;
  cursor: pointer;
}

.com_head {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}

.rg_box_row {
  display: flex;
  flex-direction: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.same_row {
  display: flex;
  flex-direction: space-between;
  /* align-items: center; */
  gap: 10px;
}

.txt_p {
  font-size: 12px;
  color: var(--color-text);
  margin-bottom: 5px;
}

.tt_line {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  color: var(--color-text);
}

.form_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}

.item_img_box {
  width: 170px;
  height: 165px;
  object-fit: cover;
  border-radius: 10px;
  border: 1px dashed gray;
}

.file_list > .icon {
  cursor: pointer;
  font-size: 30px;
  background-color: #bb3a3a;
  border-radius: 50%;
  padding: 7px;
  color: white;
  transform: scale(1) translate(470%, -5%);
  position: absolute;
  z-index: 10;
}

.file_list > .featured {
  cursor: pointer;
  font-size: 30px;
  background-color: #3eb489;
  border-radius: 50%;
  padding: 6px;
  color: white;
  position: absolute;
  z-index: 10;
}

.file_list > .notFeatured {
  cursor: pointer;
  font-size: 30px;
  background-color: #fff;
  border: 1px solid #3eb489;
  color: #3eb489;
  border-radius: 50%;
  padding: 6px;
  position: absolute;
  z-index: 10;
}
