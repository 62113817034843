@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import "~antd/dist/antd.css";

body {
  font-family: "Roboto", sans-serif;
  line-height: 1.7;
}

* {
  margin: 0;
  /* padding: 0; */
}

:root {
  --color-bg: #000000e3;
  --color-primary: #000000e3;
  --color-primary-hover: #2C3333;
  --color-secondary: #2c3e50;
  --color-secondary-hover: #151d25;
  --color-text: #455560;
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6);
  --color-white-smoke: #f5f5f5;
  --color-gray: #646464;

  --font-size-base: 0.9rem;
  --font-size-lg: 1.3rem;
  --font-size-sm: 0.8rem;
  --font-size-xs: 0.7rem;

  --space-base: 0.25rem;
  --space-lg: 0.5rem;
  --space-sm: 0.125rem;
  --space-xs: 0.0625rem;
  --space-xxs: 0.03125rem;

  --border-radius-base: 0.25rem;
  --border-radius-lg: 0.5rem;
  --border-radius-sm: 0.125rem;
  --border-radius-xs: 0.0625rem;
  --border-radius-xxs: 0.03125rem;

  --box-shadow-base: 0 0 10px 0px #0000002d;

  --container-width-lg: 80%;
  --container-width-md: 86%;
  --container-width-sm: 90%;

  --transition: all 400ms ease-in-out;
}

.full_width {
  width: 100%;
}

html {
  scroll-behavior: smooth;
}

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

.text-light {
  color: var(--color-light);
}

a {
  color: var(--color-primary);
  text-decoration: none;
  transition: var(--transition);
}

a:hover {
  color: var(--color-text);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.primary__btn {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--color-white);
  border-radius: 5px;
}

.primary__btn:hover,
.primary__btn:focus,
.primary__btn:active {
  background-color: var(--color-primary-hover) !important;
  border-color: var(--color-primary-hover) !important;
  color: var(--color-white) !important;
}

.default__btn {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  color: var(--color-white);
  border-radius: 5px;
}

.default__btn:hover,
.default__btn:focus,
.default__btn:active {
  background-color: var(--color-secondary-hover) !important;
  border-color: var(--color-secondary-hover) !important;
  color: var(--color-white) !important;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: #909090d9;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #333;
}

p {
  margin: 0;
  padding: 0;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn_up {
  border: 1px dashed gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  width: 165px;
  height: 165px;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.file_list > .anticon {
  cursor: pointer;
  font-size: 20px;
  background-color: #bb3a3a;
  border-radius: 50%;
  padding: 7px;
  color: white;
  transform: scale(1) translate(393%, 400%);
  position: absolute;
  z-index: 10;
}

.up_image_view {
  width: 165px;
  height: 165px;
  border-radius: 5px;
  object-fit: contain;
  border: 1px dashed gray;
}

.react-pdf__Page__canvas {
  /* margin: 0 auto; */
  width: 165px !important;
  height: 165px !important;
  object-fit: cover !important;
  border: 1px dashed gray;
  border-radius: 5px;
}

.react-pdf__Page__textContent {
  display: none;
}

iframe {
  overflow: hidden !important;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES)================= */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }
}

/* ================ MEDIA QUERIES (SMALL DEVICES)================= */
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }
}


span.span_active {
  background-color: #43a08b;
  font-size: 12px;
  color: white;
  padding: 3px 8px;
  border-radius: 10px;
}

span.span_inactive {
  background-color: #df0000;
  font-size: 12px;
  color: white;
  padding: 3px 8px;
  border-radius: 10px;
}